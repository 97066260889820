.profile-cards-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  padding: 20px;
  background-color: #2b2d42;
  color: #fff;
  
}
.profile-card {
  background-color: #454868;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  /*  */
  /* height: 400px; */
  height: 450px;

}
.profile-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  /*  */
  height: 150px;
}
.profile-image {
  /* width: 60px;
  height: 60px; */
  border-radius: 50%;
  margin-right: 10px;
  width: 80px;
  height: 80px;
  object-fit: cover;
}
.profile-info h3 {
  margin: 0;
  font-size: 18px;
  margin-left: 9px;
}
.profile-info p {
  /* margin: 5px 0; */
  margin: 5px 0px;
  font-size: 14px;
  color: #ccc;
  width: max-content;
  /*  */
  height: 20px;
}
.profile-body p {
  /* margin-bottom: 10px; */
  height: 5px;
}
/*  */

.skills {
  display: flex;
  flex-wrap: wrap;
  /* margin-bottom: 20px; */
  /*  */
  /* margin-top: 20px; */
  margin-top: 30px;
  height: 40px;
  /* margin-bottom: 40px; */
  margin-bottom: 60px;
  
}
.skill-tag {
  background-color: #8e44ad;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  margin-right: 5px;
  margin-bottom: 5px;
  font-size: 12px;
}
.view-profile-btn {
  background-color: #9b59b6;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  /* padding: 10px 40px; */
  /*  */
  padding: 10px 40px;
  
  /* align-items: baseline; */
  
  
}
.view-profile-btn:hover {
  background-color: #8e44ad;
}

/* ------------------ */
.container {
  max-width: 1450px !important;
  margin: 0 auto;
}

.profile-body {
  /* Add this style to the parent div wrapping the button */
  text-align: center;
  /*  */
  height: 200px;
  display: flex;
  flex-direction: column;
  gap: 4;
}

.view-profile-btn {
  /* Add this style to the button */
  margin-top: 10px; /* Adjust as needed */
  
}

.profile-body p {
  /* Add this style to the parent div wrapping the button */
  text-align: start;
}
