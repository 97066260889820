.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
}

h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

p {
  padding: 0% 6%;
}

.countdown {
  font-size: 2rem;
}

@media (max-width: 768px) {
  h1 {
    font-size: 2rem;
  }

  .countdown {
    font-size: 1.5rem;
  }
}

@media (max-width: 576px) {
  h1 {
    font-size: 1.5rem;
  }

  .countdown {
    font-size: 1rem;
  }
}

.diagonal-split {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50%;
  background: linear-gradient(-45deg, #5869ff83 50%, transparent 50%);
  animation: animateDiagonalSplit 1s forwards;
}

@keyframes animateDiagonalSplit {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes animateDiagonalSplit {
  0% {
    width: 0;
  }
  100% {
    width: 90%;
  }
}

/* Add this style to CompanyPage.css */
.p-3 {
  position: relative;
}

.p-3:hover {
  transform: translateY(-5px); /* Adjust the value as needed */
}

.p-3 h3,
.p-3 p {
  position: relative;
  z-index: 2;
}
