/* CandidateResumeDetails.css */

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow-x: hidden; /* Prevent horizontal scrolling */
    max-width: 100vw;
    background: #1a1934;
    color: #fff; /* Text color set to white */
  }
  
  .card {
    border-radius: 2px;
    width: 90%;
    box-shadow: 0 4px 8px #9900ff;
    border: 1px solid #9900ff;
    transition: 0.3s;
    max-width: 1000px;
    margin-top: 40vh;
    margin: 20px;
    color: #fff; /* Text color set to white */
    background: #1a1934;
  }
  
  .card-body {
    padding: 20px;
    /* margin-top: 500px; */
    color: #fff; /* Text color set to white */
    background: #1a1934;
    border-radius: 20px;
  }
  
  .profile-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .profile-img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 20px;
    object-fit: cover;
  }
  
  .skillss {
    margin-top: 10px;
  }
  
  .skill-tag {
    display: inline-block;
    color: #fff; /* Text color set to white */
    padding: 3px 8px;
    margin: 2px;
    border-radius: 10px;
    font-size: 0.875rem;
    border: 1px solid #9900ff;
  }
  
  .transcript-container {
    height: 400px;
    overflow-y: scroll;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;
    margin-top: 20px;
    color: #fff; /* Text color set to white */
  }
  
  /* This styles the scrollbar track */
  ::-webkit-scrollbar-track {
    background: #1a1934;
  }
  
  /* This styles the scrollbar handle */
  ::-webkit-scrollbar-thumb {
    background: #9900ff;
  }
  
  /* Optional: styles the scrollbar handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #631f1f;
  }
  
  /* Sets the size of the scrollbar */
  ::-webkit-scrollbar {
    width: 12px;
  }
  
  /* Small devices (phones, 480px and below) */
  @media only screen and (max-width: 480px) {
    .card {
      margin-left: 5%;
      width: 90%;
    }
  }
  
  /* Medium devices (tablets, 481px to 768px) */
  @media only screen and (min-width: 481px) and (max-width: 768px) {
    .card {
      margin-left: 5%;
      width: 90%;
    }
  }
  
  /* Large devices (768px to 968px) */
  @media screen and (min-width: 769px) and (max-width: 968px) {
    .card {
      margin-left: 5%;
      width: 90%;
    }
  }
  
  /* ----------------- */
  
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
  }
  
  .card {
    background-color: #1a1934;
    border: 1px solid #9900ff;
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    margin-top: 50px;
    max-width: 700px;
    width: 100%;
  }
  
  .card-body {
    padding: 20px;
  }
  
  .card-title {
    color: #fff;
  }
  
  .profile-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .profile-img {
    /* width: 50px;
      height: 50px; */
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-right: 20px;
    object-fit: cover;
  }
  
  .skills {
    margin-top: 20px;
  }
  
  .skill-tag {
    display: inline-block;
    /* background-color: #9900ff; */
    color: #fff;
    padding: 5px 10px;
    margin-right: 10px;
    border-radius: 10px;
    border: 1px solid #9900ff;
  }
  
  .video-container {
    position: relative;
    width: 100%;
  }
  
  video {
    width: 100%;
    border-radius: 10px;
  }
  
  .transcript-container {
    margin-top: 20px;
  }
  
  pre {
    color: #fff;
    white-space: pre-wrap;
  }
  