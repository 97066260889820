body {
  background-color: #1a1934;
  color: #ffffff;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  height: 98vh;
}

.sidebar {
  background-color: #101222;
  color: #fff !important;
}

.sidebar a {
  color: #fff !important;
}

.dashboard-header {
  background-color: #0f1221;
  padding: 0.75rem 1rem;
  box-shadow: 0 2px 4px rgb(0 0 0 / 10%);
  color: #ffffff;
  border: 0.5px solid #9900ff;
  border-radius: 6px;
}
.card-border {
  border: 1px solid #9900ff;
}

.card {
  border-radius: 0.5rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #f3f2ff;
  background-clip: border-box;
  border: 0.5px solid #9900ff;
  border-radius: 0.25rem;
  color: #343a40;
}

.card-header {
  background-color: #1a1934;
  color: #fff;
  border-bottom: 0;
  border-radius: 2px 2px 0px 0px !important;
}

/* StudentDashboard.css */
.calendar {
  background-color: #ffffff;
  color: #333;
  border-radius: 22px;
  padding: 16px;
  border: 1px solid #9900ff;
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.calendar-body {
  margin-top: 10px;
}

.calendar-week-day {
  display: flex;
  justify-content: space-between;
}

.calendar-days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
}

.calendar-days div {
  text-align: center;
  padding: 5px;
  cursor: pointer;
}

.appointment-slot {
  padding: 5px;
  margin-bottom: 5px;
  background-color: #eeeeee;
  border-radius: 4px;
}

.appointment-slot.booked {
  background-color: #ffcccc;
}
.calendar-day {
  text-align: center;
  padding: 5px;
  cursor: pointer;
}

/* Style for the active calendar day */
.calendar-day.active {
  border: 1px solid #9900ff;
  background-color: #9900ff2d;
  border-radius: 10%;
}
