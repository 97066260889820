/* @font-face {
  font-family: "Montserrat-Medium";
  src: url("/src/fonts/Montserrat-Regular.woff") format("woff")
    url("//src/fonts//Montserrat-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  background-color: #1b1935;
  font-family: "Montserrat-Medium";
} */
body {
  margin: 0;
  padding: 0;
  background-color: #1b1935;
}
.desktop {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.frame-5-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.frame-5 {
  background-color: #1b1935;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.overlap {
  position: relative;
  width: 100%;
  padding: 20px;
  background-color: #1b1935;
}

.navbartext {
  font-family: "Montserrat-Medium";
}
.desktop {
  position: relative;
  width: 100%;
  height: 220vh;
  /* background-color: #1b1935; */
}

.desktop .overlap {
  position: absolute;
  width: 1320px;
  height: 446px;
  top: 0;
  left: 60px;
}

.desktop .rectangle {
  position: absolute;
  width: 1056px;
  height: 137px;
  top: 321px;
  left: 200px;
  border-radius: 0px 0px 16px 16px;
  border: 1px solid;
  border-color: #9806ff;
}

.desktop .img {
  position: absolute;
  width: 1318px;
  height: 323px;
  top: 0;
  left: 0;
  border: 1px solid #fff;
  border-radius: 0px 0px 16px 16px;
  background: #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: center;
}

.desktop .div {
  position: absolute;
  width: 1271px;
  height: 87px;
  top: 236px;
  left: 49px;
  background-color: #1b1935;
  border-radius: 0px 0px 16px 16px;
  border: 1px solid;
  border-color: #9806ff;
}

.desktop .ellipse {
  position: absolute;
  width: 200px;
  height: 200px;
  top: 182px;
  left: -9px;
  border: 1px solid #fff;
  border-radius: 108px;
  background-color: #9806ff;
}
.desktop .ellipse-1 {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 255px;
  left: 70rem;
  border: 1px solid #3988d5;
  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3988d5;
}

.desktop .ellipse-2 {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 255px;
  left: 1118px;
  border: 1px solid #fff;
  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.desktop .ellipse-3 {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 255px;
  left: 73.6rem;
  border: 1px solid #1aed07;
  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1aed07;
}

.desktop .ellipse-4 {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 255px;
  left: 1238px;
  border: 1px solid #47a9f9;
  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #47a9f9; */
}

.desktop .text-wrapper {
  position: absolute;
  top: 241px;
  left: 214px;
  font-family: "Montserrat-Medium", Helvetica;
  font-weight: 500;
  color: #ffffff;
  font-size: 24px;
  letter-spacing: 0;
  line-height: normal;
}

.desktop .text-wrapper-2 {
  position: absolute;
  top: 272px;
  left: 214px;
  font-family: "Montserrat-Medium", Helvetica;
  font-weight: 500;
  color: #ffffff;
  font-size: 12px;
  letter-spacing: 0;
  line-height: normal;
}

.desktop .text-wrapper-3 {
  position: absolute;
  top: 241px;
  left: 315px;
  font-family: "Montserrat-Medium", Helvetica;
  font-weight: 500;
  color: #ffffff;
  font-size: 24px;
  letter-spacing: 0;
  line-height: normal;
}

.desktop .text-wrapper-4 {
  position: absolute;
  top: 272px;
  left: 299px;
  font-family: "Montserrat-Medium", Helvetica;
  font-weight: 500;
  color: #ffffff;
  font-size: 12px;
  letter-spacing: 0;
  line-height: normal;
}

.desktop .text-wrapper-5 {
  position: absolute;
  top: 289px;
  left: 214px;
  font-family: "Montserrat-Medium", Helvetica;
  font-weight: 500;
  color: #ffffff;
  font-size: 12px;
  letter-spacing: 0;
  line-height: normal;
}

.desktop .text-wrapper-6 {
  position: absolute;
  top: 289px;
  left: 299px;
  font-family: "Montserrat-Medium", Helvetica;
  font-weight: 500;
  color: #ffffff;
  font-size: 12px;
  letter-spacing: 0;
  line-height: normal;
}

.desktop .frame {
  display: inline-flex;
  align-items: flex-start;
  gap: 10px;
  position: absolute;
  top: 255px;
  left: 1058px;
}

.desktop .ellipse-5 {
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  border: 1px solid;
  border-color: #d9d9d9;
  background: #f1f0ff;
}

.desktop .vector {
  position: absolute;
  width: 32px;
  height: 28px;
  top: 11px;
  left: 9px;
  cursor: pointer;
}

.desktop .lorem-ipsum-is {
  position: absolute;
  width: 884px;
  top: 333px;
  left: 245px;
  font-family: "Montserrat-Medium", Helvetica;
  font-weight: 500;
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 0;
  line-height: normal;
}

.desktop .div-wrapper {
  display: flex;
  position: absolute;
  top: 406px;
  left: 1268px;
}

.desktop .text-wrapper-7 {
  font-family: "Montserrat-Medium", Helvetica;
  font-weight: 500;
  color: #ffffff;
  font-size: 12px;
  letter-spacing: 0;
  line-height: normal;
  border: 1px solid #9806ff;
  border-radius: 4px;
  padding: 4px 15px;
  background-color: #9806ff;
}

.desktop .frame-2 {
  display: flex;
  position: absolute;
  top: 406px;
  left: 1318px;
}

.desktop .frame-3 {
  display: flex;
  position: absolute;
  top: 406px;
  left: 1368px;
}

.desktop .frame-4 {
  display: flex;
  position: absolute;
  top: 406px;
  left: 1428px;
}

.desktop .text-wrapper-8 {
  position: absolute;
  top: 373px;
  left: 1220px;
  font-family: "Montserrat-Medium", Helvetica;
  font-weight: 500;
  color: #ffffff;
  font-size: 12px;
  letter-spacing: 0;
  line-height: normal;
}

.desktop .overlap-group {
  position: absolute;
  width: 1056px;
  height: 92px;
  top: 650px !important;
  left: 200px;
}

.desktop .line {
  position: absolute;
  width: 1139px;
  top: 76px;
  left: -8px;
  border: 1px solid;
  border-color: #9900ff;
  z-index: 1;
  transform: rotate(180deg);
  height: 2px;
  background: #9900ff;
}

.desktop .rectangle-2 {
  position: absolute;
  width: 250px;
  height: 45px;
  top: 47px;
  left: 860px;
  background-color: #353353;
  border-radius: 24px 24px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 10px;
}

.desktop .rectangle-3 {
  position: absolute;
  width: 250px;
  height: 45px;
  top: 47px;

  left: 430px;
  background-color: #353353;
  border-radius: 24px 24px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 5px;
}

.desktop .rectangle-4 {
  position: absolute;
  width: 250px;
  height: 45px;
  top: 42px;
  left: 0;
  background-color: #353353;
  border-radius: 24px 24px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin: 0px 10px; */
}

.desktop .text-wrapper-9 {
  position: absolute;
  top: 37px;
  left: 135px;
  font-family: "Montserrat-Medium", Helvetica;
  font-weight: 500;
  color: #ffffff;
  font-size: 16px;
  letter-spacing: 0;
  line-height: normal;
}

.desktop .text-wrapper-10 {
  position: absolute;
  top: 37px;
  left: 487px;
  font-family: "Montserrat-Medium", Helvetica;
  font-weight: 500;
  color: #ffffff;
  font-size: 16px;
  letter-spacing: 0;
  line-height: normal;
}

.desktop .text-wrapper-11 {
  position: absolute;
  top: 37px;
  left: 835px;
  font-family: "Montserrat-Medium", Helvetica;
  font-weight: 500;
  color: #ffffff;
  font-size: 16px;
  letter-spacing: 0;
  line-height: normal;
}
.interview-video,
.interview-transcript,
.interview-evaluation {
  width: 100%;
  height: 76vh;
  background: black;
  top: 7rem;
  position: absolute;
  display: none; /* Hide all sections by default */
  left: 32px;
}

.interview-video.active,
.interview-transcript.active,
.interview-evaluation.active {
  display: block; /* Show the active section */
}

.rectangle-2.active,
.rectangle-3.active,
.rectangle-4.active {
  background-color: grey; /* Change background color for the active button */
}

/* Additional CSS for the buttons */
.rectangle-2,
.rectangle-3,
.rectangle-4 {
  cursor: pointer;
}

/* .desktop .rectangle-5 {
  position: relative;
  width: 100%;
  height: 80vh;
  top: 43rem;
  left: 0;
  background: black;
  display: flex;
  align-items: center;
  justify-content: center;
} */
.subrectangle {
  /* position: relative; */
  width: 100%;
  height: 80vh;
  top: 49rem;
  left: 0;
  background: #000;
  margin: 0px 300px 0px 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
}
.subrectangle2 {
  /* position: relative; */
  width: 100%;
  height: 80vh;
  top: 49rem;
  left: 0;
  background: #000;
  margin: 0px 300px 0px 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
}
.subrectangle3 {
  /* position: relative; */
  width: 100%;
  height: 80vh;
  top: 49rem;
  left: 0;
  background: #000;
  margin: 0px 300px 0px 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
}

.desktop .frame-5 {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 512px;
  /* left: 136px; */
  gap: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: -webkit-fill-available;
}

.desktop .p {
  font-family: "Montserrat-Medium", Helvetica;
  font-weight: 700;
  color: #ffffff;
  font-size: 32px;
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: 0.5rem;
  width: max-content;
}

.desktop .frame-6 {
  display: flex;
  align-items: center;
  gap: 8px;
}

.desktop .frame-7 {
  width: 17px;
  height: 17px;
}

.desktop .text-wrapper-12 {
  font-family: "Montserrat-Medium", Helvetica;
  font-weight: 500;
  color: #ffffff;
  font-size: 24px;
  letter-spacing: 0;
  line-height: normal;
}
.rectangle-2,
.rectangle-3,
.rectangle-4 {
  cursor: pointer;
  /* Your styles for the rectangles */
}
.rectangle-2,
.rectangle-3,
.rectangle-4 {
  cursor: pointer;
  padding: 10px;
  margin: 5px;
}

.rectangle-2.active,
.rectangle-3.active,
.rectangle-4.active {
  background-color: #9806ff; /* Example active background color */
  background: linear-gradient(45deg, #9806ff, #7b28b7); /* Example gradient */
}

.rectangle-2.inactive,
.rectangle-3.inactive,
.rectangle-4.inactive {
  background-color: #353353; /* Example inactive background color */
  border: 1px solid #9900ff;
}

.lock {
  top: 496px;
  position: relative;
  padding: 0px 42px;
  width: 100%;
  height: 17vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sublock {
  width: -webkit-fill-available;
  display: grid;
  justify-content: center;
}

.p {
  padding: 0% 0%;
}
.Score {
  background: #1c1936;
  width: 12%;
  height: 5vh;
  /* color: aqua; */
  top: 16rem;
  position: absolute;
  left: 43rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Montserrat-Medium", Helvetica;
  border: 1px solid #9900ff;
  padding: 6px 6px;
  border-radius: 8px;
}

.popupbox {
  position: relative;
  background-color: #1c1936;
  width: 90%;
  max-width: 500px;
  height: auto;
  z-index: 1;
  margin: 18rem auto;
  border: 1px solid #fff;
  border-radius: 4px;
  padding: 16px;
  box-sizing: border-box;
}

.close-icon {
  position: absolute;
  top: 3px;
  right: 3px;
  cursor: pointer;
}

.subpopupbox {
  width: 100%;
  height: auto;
  /* background: antiquewhite; */
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  gap: 10px;
}

.popupsecbox {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}

.text {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4px;
}

.shraiconbg {
  width: 73px;
  height: 73px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  background: aquamarine;
  padding: 4px;
}

.Linkdin,
.Whatsappimg,
.Insta,
.facebook {
  width: 50px;
  height: 50px;
}
.shearicon {
  width: 45px;
  height: 45px;
}

.url {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 8px;
}

.link-url {
  height: 5vh;
  width: 70%;
  background: transparent;
  margin: 16px 0;
  border: 1px solid #000;
  border-radius: 24px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
  box-sizing: border-box;
  border: 1px solid #fff;
}

.link-path {
  height: 5vh;
  width: 20%;
  background: #419be9;
  margin: 16px 0;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.text-style {
  font-size: 16px;
}

@media (max-width: 768px) {
  .popupbox {
    width: 80%;
    margin-top: 10rem;
  }

  .link-url {
    width: 60%;
  }

  .link-path {
    width: 30%;
  }
}

@media (max-width: 480px) {
  .popupbox {
    width: 95%;
    margin-top: 5rem;
  }

  .link-url,
  .link-path {
    width: 100%;
    margin: 8px 0;
  }
}

@media (min-width: 615px) and (max-width: 1000px) {
  .lock {
    left: 380px;
  }
}
@media (max-width: 615px) {
  .lock {
    left: 474px;
  }
}

@media (max-width: 320px) {
  .desktop .ellipse-1 {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 555px;
    top: 255px;
  }

  .desktop .ellipse-5 {
    width: 30px;
    height: 30px;
    left: -21rem;
  }
  .desktop .ellipse-3 {
    position: absolute;
    width: 30px;
    height: 30px;
    top: 255px;
    left: 605px;
    border: 1px solid #fff;
    border-radius: 32px;
  }
  .desktop .ellipse-4 {
    left: 659px;
    width: 30px;
    height: 30px;
  }

  .desktop .img {
    position: absolute;
    width: 1141px;
    height: 323px;
    top: 0;
    left: 0;
    border: 1px solid #fff;
    border-radius: 0px 0px 4px 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .desktop .rectangle {
    width: 900px;
  }
  .desktop .lorem-ipsum-is {
    width: 780px;
  }
  .desktop .overlap-group {
    position: absolute;
    width: 1056px;
    height: 92px;
    top: 451px;
    left: 112px;
  }
  .desktop .div {
    width: 1092px;
    border-radius: 0px 0px 4px 4px;
  }
  .desktop .frame-5 {
    width: 390%;
    left: 2px;
  }
  .desktop .frame {
    left: 851px;
  }
  .desktop .ellipse-4 {
    left: 1066px;
  }
  .desktop .overlap {
    width: 662px;
  }
  .desktop .p {
    font-size: 21px;
  }
  .desktop .text-wrapper-12 {
    font-size: 20px;
  }
}
@media (min-width: 308px) and (max-width: 320px) {
  .desktop .ellipse-1 {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 969px;
    top: 255px;
  }
  .desktop .ellipse-3 {
    position: absolute;
    width: 30px;
    height: 30px;
    top: 255px;
    left: 1019px;
    border: 1px solid #fff;
    border-radius: 32px;
  }
  .desktop .ellipse-5 {
    width: 30px;
    height: 30px;
    left: 4rem;
  }
}
@media (max-width: 307px) {
  .desktop .frame {
    display: inline-flex;
    align-items: flex-start;
    gap: 10px;
    position: absolute;
    top: 255px;
    left: 795px;
  }
  .desktop .ellipse-1 {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 581px;
    top: 255px;
  }

  .desktop .ellipse-5 {
    width: 30px;
    height: 30px;
    left: -309px;
  }
  .desktop .ellipse-3 {
    position: absolute;
    width: 30px;
    height: 30px;
    top: 255px;
    left: 620px;
    border: 1px solid #fff;
    border-radius: 32px;
  }
  .desktop .ellipse-4 {
    left: 659px;
    width: 30px;
    height: 30px;
  }
  .desktop .div {
    width: 792px;
  }
  .desktop .img {
    width: 840px;
    border-radius: 0px 0px 16px 4px;
  }
  .desktop .rectangle {
    width: 580px;
  }
  .desktop .lorem-ipsum-is {
    width: 584px;
    top: 335px;
    left: 230px;
  }
  p {
    padding: 0% 6%;
    font-size: 9px;
    width: 66%;
  }
  .desktop .rectangle-2 {
    position: absolute;
    width: 172px;
    height: 47px;
    top: 44px;
    left: 458px;
    background-color: #353353;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border-radius: 0px 6px 6px 0px; */
  }
  .desktop .rectangle-3 {
    width: 172px;
    height: 47px;
    left: 237px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .desktop .rectangle-4 {
    width: 172px;
    height: 47px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border-radius: 6px 0px 0px 6px; */
  }
  /* .desktop .overlap-group {
    width: 490%;
    height: 92px;
    top: 482px;
    left: 152px;
  } */
  /* .desktop .frame-5 {
    width: 390%;
  } */
  .desktop .frame {
    left: 851px;
  }
  /* .desktop .ellipse-4 {
    left: 1066px;
  } */
  .desktop .overlap {
    width: 662px;
  }
  .desktop .line {
    position: absolute;
    width: 706px;
    top: 76px;
    left: -34px;
  }
  .desktop .overlap-group {
    width: 328%;
    height: 92px;
    top: 482px;
    left: 163px;
  }
  .desktop .rectangle-5 {
    position: relative;
    width: 100%;
    height: 80vh;
    top: 43rem;
    left: 332px;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 241vh;
    padding: 0px 76px 0px 0px;
  }
  .lock {
    left: 334px;
    top: 33rem;
  }
}
@media (min-width: 234px) and (max-width: 307px) {
  .interview-video,
  .interview-transcript,
  .interview-evaluation {
    width: 227%;
    height: 76vh;
    background: black;
    top: 7rem;
    position: absolute;
    display: none;
    left: 0px;
  }
  .desktop .overlap-group {
    width: 100%;
    height: 92px;
    top: 482px;
    left: 163px;
  }
}

@media (max-width: 234px) {
  .lock {
    left: 321px;
    top: 34rem;
  }

  .Score {
    background: #1c1936;
    width: 14%;
    height: 12vh;
    /* color: aqua; */
    top: 21rem;
    position: absolute;
    left: 36rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Montserrat-Medium", Helvetica;
    border: 1px solid #9900ff;
    padding: 6px 6px;
    border-radius: 8px;
  }
  .scoretext {
    color: rgb(255, 255, 255);
    font-size: 12px;
  }
}

@media (max-width: 217px) {
  .desktop .img {
    width: 710px;
    border-radius: 0px 0px 16px 4px;
  }
  .desktop .div {
    width: 662px;
  }
  .desktop .rectangle {
    width: 481px;
  }
  .desktop .overlap-group {
    left: 70px;
  }
  .interview-video,
  .interview-transcript,
  .interview-evaluation {
    left: 0px;
  }
}
@media (max-width: 1391px) {
  .desktop .frame-5 {
    width: 390%;
    left: 2px;
  }
}

/* new code  */
/* .popupbox {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  background: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  z-index: 1000;
  cursor: pointer;
}

.subpopupbox {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.popupsecbox {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.shraiconbg {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.text {
  margin-left: 10px;
}

.url {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.link-url {
  color: blue;
  cursor: pointer;
}

.link-path {
  cursor: pointer;
} */
