/* styles.css */

/* General styles */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  /* background-color: #333; */
  color: #fff;
}

.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding: 1rem;
}

/* Header styles */
.header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}

.logo-container {
  max-width: 400px;
}

.logo {
  width: 100%;
  height: auto;
}

/* Section styles */
.sections {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 800px;
}

.section {
  background: linear-gradient(to right, #000000, #1a1934);
  border-radius: 8px;
  padding: 2rem;
  margin-bottom: 2rem;
  width: 100%;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  transition: box-shadow 0.3s ease;
}

.section:hover {
  box-shadow: 0 4px 12px rgba(255, 255, 255);
}

.content {
  text-align: center;
}

.icon-container {
  height: 80px;
  margin-bottom: 1rem;
}

h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

p {
  margin-bottom: 1.5rem;
}

.btn {
  background-color: #00bcd4;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn:hover {
  background-color: #008c9e;
}

.login {
  margin-top: 1rem;
  font-size: 0.9rem;
}

/* Responsive styles */
@media (min-width: 768px) {
  .sections {
    flex-direction: row;
    justify-content: space-between;
  }

  .section {
    margin-bottom: 0;
    width: 48%;
  }
}
